.regCustomTextField .react-tel-input .form-control {
  padding: 15px 60px !important;
  width: 100% !important;
  margin: 5px 0px !important;
  border: 2px solid #2b374412 !important;
  box-shadow: 0px 10px 20px 0px #2b374412 !important;
  border-radius: 26px !important;
}

.regCustomTextField {
  text-align: initial !important;
}

.regCustomTextField .react-tel-input .form-control:focus,
.regCustomTextField .react-tel-input .form-control:hover {
  background-color: #fff !important;
  border-color: #0b80ff !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.regCustomTextField .react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #2196f3 !important;
  box-shadow: none !important;
  border: none !important;
}

.slide-toggle-btn {
  position:fixed;
  top:64px;
  right:20px;
  z-index:1;
  float: left;
  cursor: pointer;
  background-color: #2196f3;
  color: #000;
  border-radius: 0px 0px 5px 5px;
  padding: 0px 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.slide-toggle-btn img{
  width: 25px;
  height: 25px;
}

.slide-toggle-btn .toggle-arrow-top:after {
  content: " ";
  position: absolute;
  top: -12px;
  margin:"auto";
  left:0;
  right:0;
  font-size:10px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid black;
}

.slideIn {
  animation: slide-down 3s linear backwards;
  -webkit-animation: slide-down 3s linear backwards;
  -o-animation: slide-down 3s linear backwards;
  -moz-animation: slide-down 3s linear backwards;
  max-height: 480px;
}

.slideOut {
  animation: slide-up 1s linear forwards; 
  -webkit-animation: slide-up 1s linear forwards; 
  -o-animation: slide-up 1s linear forwards; 
  -moz-animation: slide-up 1s linear forwards; 
}

.slideOut {
  max-height: 0px;
}

@media (max-width: 600px){
  .slide-toggle-btn {
    top:54px;
    padding: 2px 2px;
  }
}
@keyframes slide-down {
  0% {
    max-height: 0px;
    visibility:hidden;
    overflow: hidden;
  }
  50% {
    max-height: 240px;
    overflow: hidden;
  }
  100% {
    max-height: 480px;
  }
}

@keyframes slide-up {
  0% {
    max-height: 480px;
  }
  50% {
    max-height: 240px;
    overflow: hidden;
  }
  100% {
    max-height: 0px;
    visibility:hidden;
    overflow: hidden;
  }
}
