@import "~video-react/dist/video-react.css";

body {
  font-family: "Satoshi", serif !important;
  background-color: #fff !important;
}

body::-webkit-scrollbar{
 width: 0.3em;;
 height: 0.3em;
}
body::-webkit-scrollbar-thumb{
 background-color: rgba(0,0,0,.1);
 }
body::-webkit-scrollbar-track{
 box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

::-webkit-scrollbar{
 width: 0.3em;
 height: 0.3em;
}
::-webkit-scrollbar-thumb{
 background-color: rgba(0,0,0,.1); 
}
::-webkit-scrollbar-track{
 box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
 

  .Toastify__toast{
    box-shadow: none;
    background-color: unset;
  }
 
  .Toastify__toast svg{
    color: #000;
  }

  .Mui-disabled{
    color: white;
  }


  .main{
    position: fixed;
top: 0;
left: 0;
z-index: 19999;
width: 100vw;
height: 100vh;
background-color: #000;
      opacity: 0.5;
  
  }

#spinner {
    position: absolute;
    left: 49%;
    top: 44%;
    height: 40px;
    width: 40px;
 
    animation: rotation .8s infinite linear;
    border-left: 6px solid rgba(0,174,239,.15);
    border-right: 6px solid rgba(0,174,239,.15);
    border-bottom: 6px solid rgba(0,174,239,.15);
    border-top: 6px solid rgba(0,174,239,.8);
    border-radius: 100%;
    z-index: 1000000;
 }

 .reset_rotate {
  animation: spin 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 
 @-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
 }
 @-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
 }
 @-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
 }
 @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
 }

 .breakMeassageOR{
  text-align: center;
  color: #666362;
 }

 .breakMeassageOR::before{
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  height: 1px;
  background: #b5b5b5;
  width: 46%;
  margin: 0px 10px;
 }
 .breakMeassageOR::after{
  content: "";
  position: absolute;
  top: 12px;
  right: 0;
  height: 1px;
  background: #b5b5b5;
  width: 46%;
  margin: 0px 10px;
 }
 #toolbar {
  display: none !important;
 }

 .react-tel-input .form-control{
  padding: 10px 60px !important;
  width: 100%!important;
  margin-top: 5px!important;
  border-radius: 0 !important; 
  border: 1px solid #edecec !important; 
 } 
 .react-tel-input .form-control:focus{
  background-color:#fff!important;
  border-color: none !important;
  outline: 0!important;
  box-shadow: none!important;
  }
 

  .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
     border-color: #CACACA !important; 
    box-shadow: none!important;  
    border: none!important;  
  }

  .fullscreen-enabled  .leftSide-chat{
    height: 100vh !important;
  }

  .fullscreen-enabled  .window-chat{
    height: 85vh !important;
  }
  
  .article-head {
    font-size: 22;
    font-weight: 600;
  }
  /* .article-body {
    font-size: 22;
    width: 250px;
  } */

  .article-body {
      /* max-width: 500px;
      margin: auto;
      border: 3px solid #73AD21; */
      overflow-x: inherit;
  text-overflow: ellipsis;
  word-wrap: break-word;
  }

  .button-footer {
    color: black;
    float: right;
    padding: 12px 13px;
    max-width: 50%;
    min-width: 40%;
    background: #d9fdd3;
    line-break: anywhere;
    border-radius: 15px 0px 15px 15px;
  }

.footer-reply-button {
  background-color: #d9fdd3;
  border-radius: 3px;
  border: none;
  /* border: 1px solid #7aa7c7; */
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #3cc1e7;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 45%;
}

.footer-reply-button:hover,
.footer-reply-button:focus {
  background-color: #c3f0bb;
  color: #33a1bf;
}

.footer-reply-button:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.footer-reply-button:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #33a1bf;
}